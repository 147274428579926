



















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class RootSettings extends Vue {
  rootFeaturesTabs = [
    {
      name: 'Payment Providers',
      icon: 'DollarSignIcon',
      component: () => import('@/components/Root/PaymentProviders.vue')
    },
  ]
}
